@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300&display=swap");
.serviceMain {
  margin-top: 5rem;
}

.serviceSecMain {
  display: flex;
  justify-content: space-around;
  /* background-color: brown; */
  margin-top: 5rem;
  align-items: center;
}

.servicesSec {
  width: 50rem;
  /* background-color: rgb(129, 239, 102); */
}

.serviceOption {
  border: 1px solid black;

  text-align: center;
  background-color: rgba(10, 95, 18, 0.955);
  width: 35rem;
  height: 30rem;
  border-radius: 20px;
  cursor: pointer;
}

.serviceOption img {
  border-radius: 20px;
  margin-top: 0.8rem;
  /* border: 1px solid whitesmoke; */
  transition: all 0.2s ease-in-out;
}

.serviceOption h1 {
  width: 100%;
  height: 5rem;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  color: rgb(255, 255, 255);
  font-size: 2.5rem;
}

.serviceHeadH1 {
  text-align: center;
  margin-top: 4rem;
  background: rgb(135, 210, 255);
  background: linear-gradient(
    138deg,
    rgba(135, 210, 255, 1) 0%,
    rgba(132, 255, 150, 1) 51%,
    rgba(255, 206, 156, 1) 100%
  );
  font-size: 5rem;
  color: rgb(29, 95, 18);
}

.refMain {
  background: rgb(135, 210, 255);
  background: linear-gradient(
    138deg,
    rgba(135, 210, 255, 1) 0%,
    rgba(132, 255, 150, 1) 51%,
    rgba(255, 206, 156, 1) 100%
  );
  margin-top: 5rem;
  border-end-start-radius: 50%;
  border-end-end-radius: 50%;

  border: 1px solid rgb(172, 172, 172);
  margin-bottom: 5rem;
}

.refMain .refSec {
  text-align: center;
  margin-top: 3rem;
}

.refMain .refSec h1 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 3rem;
  color: rgb(29, 95, 18);
}

/* Mobile */

.serviceMobMain {
  margin-top: 5%;
}

.serviceMobHeadH1 {
  text-align: center;
  margin-top: 4rem;
  background: rgb(135, 210, 255);
  background: linear-gradient(
    138deg,
    rgba(135, 210, 255, 1) 0%,
    rgba(132, 255, 150, 1) 51%,
    rgba(255, 206, 156, 1) 100%
  );
  font-size: 3rem;
  color: rgb(29, 95, 18);
}

.serviceMobSecMain {
  display: flex;
  justify-content: space-around;
  /* background-color: brown; */
  margin-top: 5rem;
  align-items: center;
}

.servicesMobSec {
  width: 50rem;
  /* background-color: rgb(129, 239, 102); */
}

.serviceMobOption {
  border: 1px solid black;

  text-align: center;
  background-color: rgba(10, 95, 18, 0.955);
  width: 10rem;
  height: 13rem;
  border-radius: 20px;
  cursor: pointer;
}

.serviceMobOption img {
  border-radius: 20px;
  margin-top: 0.4rem;
  /* border: 1px solid whitesmoke; */
  transition: all 0.2s ease-in-out;
}

.serviceMobOption h1 {
  /* width: 100%; */
  /* height: 5rem; */
  align-items: center;
  display: flex;
  justify-content: center;
  /* margin-top: rem; */
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
}

.refMain .refMobSec {
  text-align: center;
  margin-top: 3rem;
}

.refMain h1{
  margin-top: 1rem;
}

.refMobSec{
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 1rem;
  color: rgb(29, 95, 18);
}
* {
  margin: 0;
  padding: 0;
  overflow-y: auto;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

*::-webkit-scrollbar {
  display: none;
}

html {
  background: rgb(244, 248, 253);
  background: linear-gradient(
    0deg,
    rgba(244, 248, 253, 1) 0%,
    rgba(224, 238, 255, 1) 51%,
    rgba(248, 251, 255, 1) 100%
  );
}
